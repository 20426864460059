import {
  Container,
  ContentAbout,
  ContentUpdate,
  DateUpdate,
  DescriptionAbout,
  ItemImage,
  ListImage,
  TextData,
  TitleAbout,
  TitleUpdate,
  WrapperData,
  WrapperTitle,
} from './ContentInfo.styled'
import img1 from './assets/1.png'
import img2 from './assets/2.png'
import img3 from './assets/3.png'
import img4 from './assets/4.png'
import { ArrowDown, ArrowForward } from '@shared/assets'
import { useState } from 'react'

export const ContentInfo: React.FC = () => {
  const [isDescription, setIsDescription] = useState(false)

  return (
    <Container>
      <ListImage>
        <ItemImage src={img1} alt={'img-1'} />
        <ItemImage src={img2} alt={'img-2'} />
        <ItemImage src={img3} alt={'img-3'} />
        <ItemImage src={img4} alt={'img-4'} />
      </ListImage>
      <ContentAbout>
        <WrapperTitle>
          <TitleAbout>About this game</TitleAbout>
          {isDescription ? (
            <ArrowDown onClick={() => setIsDescription(prev => !prev)} />
          ) : (
            <ArrowForward onClick={() => setIsDescription(prev => !prev)} />
          )}
        </WrapperTitle>
        <DescriptionAbout $isDescription={isDescription}>
          🚀 Aviator X - specifically for India 🇮🇳
          <br />
          <br />
          😱 Do not miss your chance - ₹ 10.000.000
          <br />
          🐺 Yo, yo, yo! We have released a new application!
          <br />
          <br />
          We have collected the best slots about in one application:
          <br />
          🔮 Plinko
          <br />
          ⚡️ Gates of Olympus
          <br />
          🎈 Balloon
          <br />
          🍬 Sweet Bonanza
          <br />
          🐺 Wolf Run
        </DescriptionAbout>
        <ContentUpdate>
          <TitleUpdate>Updated on</TitleUpdate>
          <DateUpdate>September 16, 2024</DateUpdate>
        </ContentUpdate>
        <ContentUpdate>
          <WrapperTitle>
            <TitleAbout>Data safety</TitleAbout>
            <ArrowForward />
          </WrapperTitle>
          <DescriptionAbout>
            In this section, developers can specify how applications collect and use data.
          </DescriptionAbout>
          <WrapperData>
            <TextData>No information.</TextData>
          </WrapperData>
        </ContentUpdate>
      </ContentAbout>
    </Container>
  )
}
