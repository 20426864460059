import img1 from '@pages/Install/components/Comments/assets/1.png'
import img2 from '@pages/Install/components/Comments/assets/2.png'
import img3 from '@pages/Install/components/Comments/assets/3.png'
import img4 from '@pages/Install/components/Comments/assets/4.png'
import img5 from '@pages/Install/components/Comments/assets/5.png'
import img6 from '@pages/Install/components/Comments/assets/6.png'
import img7 from '@pages/Install/components/Comments/assets/7.png'
import img8 from '@pages/Install/components/Comments/assets/8.png'
import img9 from '@pages/Install/components/Comments/assets/9.png'
import img10 from '@pages/Install/components/Comments/assets/10.png'
import img11 from '@pages/Install/components/Comments/assets/11.png'
import img12 from '@pages/Install/components/Comments/assets/12.png'
import img13 from '@pages/Install/components/Comments/assets/13.png'
import img14 from '@pages/Install/components/Comments/assets/14.png'
import img15 from '@pages/Install/components/Comments/assets/15.png'
import img16 from '@pages/Install/components/Comments/assets/16.png'
import img17 from '@pages/Install/components/Comments/assets/17.png'
import img18 from '@pages/Install/components/Comments/assets/18.png'
import img19 from '@pages/Install/components/Comments/assets/19.png'
// import img20 from '@pages/Install/components/Comments/assets/20.png'
// import img21 from '@pages/Install/components/Comments/assets/21.png'
// import img22 from '@pages/Install/components/Comments/assets/22.png'
// import img23 from '@pages/Install/components/Comments/assets/23.png'

export const listComments = [
  {
    icon: img1,
    name: 'Mirza Ahtesham',
    countStarts: 5,
    date: 'April 8, 2024',
    comment:
      'पहले गेम के बाद मेरी राय बदल गई ।  मैं विश्वास नहीं कर सकता कि मैंने कितना कमाया है! 🌟💵',
    peopleFound: 2,
  },
  {
    icon: img2,
    name: 'Babu Ahirwar',
    countStarts: 16,
    date: 'April 1, 2024',
    comment:
      'यह सबसे अच्छा कैसीनो है जिसमें मैंने कभी खेला है ।  मैंने एक घंटे में 20,000 रुपये जीते और पहले ही कार्ड से पैसे निकाल लिए हैं',
    peopleFound: 16,
  },
  {
    icon: img3,
    name: 'Hamza Khan',
    countStarts: 5,
    date: 'March 29, 2024',
    comment: 'मैं इस पर विश्वास नहीं कर सकता! यह सबसे अच्छा ऐप है जिसे मैंने देखा है',
    peopleFound: 54,
  },
  {
    icon: img4,
    name: 'ट्रेज़र\n',
    countStarts: 4,
    date: 'March 25, 2024',
    comment:
      'मैंने उत्कृष्ट एविएटर ऑनलाइन कैसीनो ऐप डाउनलोड किया, अच्छी जीत, उच्च संभावनाएँ और त्वरित भुगतान। बहुत अच्छा!!!\n',
    peopleFound: 1,
  },
  {
    icon: img5,
    name: 'Ali Hamza',
    countStarts: 5,
    date: 'March 20, 2024',
    comment:
      'मैं किसी भी व्यक्ति को ऑनलाइन कैसीनो की अत्यधिक अनुशंसा करता हूं जो एक मजेदार और सुरक्षित गेम की तलाश में है ।  मैं निश्चित रूप से यहां खेल में लौटूंगा । ',
    peopleFound: 22,
  },
  {
    icon: img6,
    name: 'Hammad Shaikh',
    countStarts: 5,
    date: 'March 9, 2024',
    comment:
      'सबसे अच्छा कैसीनो!!! मैंने 6,000 रुपये जमा किए और जैकपॉट जीता, मैं एक दिन में एक हजार से अधिक ज़्लॉटी जीतने में कामयाब रहा ।  मैं सभी को खेल की सलाह देता हूं',
    peopleFound: 6,
  },
  {
    icon: img7,
    name: 'Shazzad Hossain',
    countStarts: 5,
    date: 'March 1, 2024',
    comment:
      'मैंने एक ऑनलाइन कैसीनो में खेलने के लिए बहुत पैसा जीता और धन निकालने की सुविधा से प्रभावित हुआ ।  मैं मिनट के एक मामले में पैसे प्राप्त',
    peopleFound: 0,
  },
  {
    icon: img8,
    name: 'Abhishek Yadav',
    countStarts: 5,
    date: 'February 26, 2024',
    comment:
      'यह खेल वास्तव में काम करता है! मैंने अभी जमा किया है और उन्होंने मुझे 500% बोनस दिया है!',
    peopleFound: 34,
  },
  {
    icon: img9,
    name: 'Sajid afridi',
    countStarts: 5,
    date: 'February 24, 2024',
    comment: 'वाह! मैं हैरान हूँ!मैं हैरान हूँ! मैंने एक जमा किया और तुरंत जीत गया!',
    peopleFound: 9,
  },
  {
    icon: img10,
    name: 'Tur Hassan',
    countStarts: 5,
    date: 'February 22, 2024',
    comment:
      'मैंने कभी नहीं सोचा था कि मैं एक कैसीनो ऐप में इतना जीत पाऊंगा ।  एविएटर एक चमत्कार है! 💸😱',
    peopleFound: 1,
  },
  {
    icon: img11,
    name: 'Jani Khattak',
    countStarts: 4,
    date: 'February 18, 2024',
    comment: 'उत्कृष्ट कैसीनो, पहले ही 10 हजार रुपये निकाल चुका है',
    peopleFound: 22,
  },
  {
    icon: img12,
    name: 'Sahil Kumar',
    countStarts: 4,
    date: 'February 1, 2024',
    comment: 'मैंने अभी साइन अप किया और 13,000 घंटे में 2 रुपये जीते!',
    peopleFound: 43,
  },
  {
    icon: img13,
    name: 'Narasimha Sai',
    countStarts: 5,
    date: 'January 30, 2024',
    comment: 'एविएटर बस शानदार है! मैं पहले ही 15,000 रुपये कमा चुका हूं',
    peopleFound: 14,
  },
  {
    icon: img14,
    name: 'Duaa Rabail',
    countStarts: 4,
    date: 'January 28, 2024',
    comment:
      'मुझे अपनी पहली जमा राशि पर एक बड़ा बोनस मिला, जिसकी बदौलत मैंने 83738 रुपये सिर्फ 30 मिनट में कमाए ।  यह ऐप 5 सितारों का हकदार है',
    peopleFound: 67,
  },
  {
    icon: img15,
    name: 'Inam Ul Haq',
    countStarts: 5,
    date: 'January 20, 2024',
    comment: 'मैं समझ नहीं पा रहा हूं कि क्या हो रहा है । .. ग्रह पर सबसे अच्छा खेल!',
    peopleFound: 0,
  },
  {
    icon: img16,
    name: 'Arun Chouhan',
    countStarts: 5,
    date: 'January 19, 2024',
    comment: 'मैंने एक बेहतरीन ऐप डाउनलोड किया ।  अच्छी जीत, उच्च बाधाओं और तेजी से भुगतान ।  सुपर',
    peopleFound: 12,
  },
  {
    icon: img17,
    name: 'Owais Abbasi',
    countStarts: 5,
    date: 'January 28, 2024',
    comment:
      'मैंने कभी नहीं सोचा था कि मुझे कैसीनो में खेलने में इतना मज़ा आएगा ।  एविएटर सिर्फ नशे की लत है, और जीत पॉप अप करती रहती है ।  मैं इसकी अत्यधिक अनुशंसा करता हूं!',
    peopleFound: 23,
  },
  {
    icon: img18,
    name: 'Sharath Ms',
    countStarts: 5,
    date: 'January 12, 2024',
    comment: 'यह सच है! खेल काम कर रहा है! जमा करें और जीतें!',
    peopleFound: 19,
  },
  {
    icon: img19,
    name: 'Rohan Vj',
    countStarts: 5,
    date: 'January 11, 2024',
    comment:
      'मैं इस ऐप को इंस्टॉल करने में झिझक रहा था, लेकिन अब मैं इसका प्रशंसक बन गया हूं क्योंकि इसने मेरी जिंदगी को पूरी तरह से बदल दिया है ।  यहां एक दिन में 300 रुपए तक की कमाई बहुत अच्छी है',
    peopleFound: 43,
  },
  // {
  //   icon: img20,
  //   name: 'Ranjit Chakravarty',
  //   countStarts: 5,
  //   date: 'January 3, 2024',
  //   comment:
  //     "A very excellent company Crazy time, big wins, fast payouts and high possibilities, many sports games, very convenient and easy to use, even a newbie can figure it out in a few clicks and you are already earning money. So far, I've only heard mediocre comments about this app. I recommend everyone to try",
  //   peopleFound: 26,
  // },
  // {
  //   icon: img21,
  //   name: 'Ajit Das',
  //   countStarts: 5,
  //   date: 'January 3, 2024',
  //   comment:
  //     "Very good application, I did not even think that you can win money on Crazy time, I advise everyone to install the Crazy time application, place bets and win money, because as they say, there is never too much money Have a chance to win with pleasure, and it's great, good luck to everyone who joins Aviator, good luck placing bets in this wonderful application.",
  //   peopleFound: 23,
  // },
  // {
  //   icon: img22,
  //   name: 'Manish Mukhopadhyay',
  //   countStarts: 5,
  //   date: 'January 3, 2024',
  //   comment:
  //     'Very nice application. I never thought that I could get a lot of money. I recommend everyone to download this application',
  //   peopleFound: 19,
  // },
  // {
  //   icon: img23,
  //   name: 'Vikram Pande',
  //   countStarts: 5,
  //   date: 'December 27, 2023',
  //   comment:
  //     "Crazy time app is really good. I didn't think it was that easy. You install it and it works immediately. Overall very good. So I thought it was another scam. But I was sure that was not the case.",
  //   peopleFound: 67,
  // },
]
